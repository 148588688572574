import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { ButtonOutput } from "../button/button-output";
import { ButtonActionsType } from "../button/button-actions.type";

@Component({
  selector: "app-tab-item",
  templateUrl: "./tab-item.component.html",
  styleUrls: ["./tab-item.component.scss"],
})
export class TabItemComponent {
  /**
   * Text that should be displayed on the button
   */
  @Input() label = "";

  /**
   * Active State of te button
   */
  @Input() active: boolean = false;

  @Input() fullWidth: boolean = false;

  @HostBinding("class") get hostClasses() {
    const classList = ["tab-item"];
    if (this.active) {
      classList.push("tab-item--active");
    }
    if (this.fullWidth) {
      classList.push("tab-item--full-width");
    }
    return classList;
  }

  @HostBinding("attr.tabindex") tabindex = "0";

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostListener("click", ["$event"])
  public click(evt: Event) {
    this.action.emit({
      type: ButtonActionsType.Click,
    });
  }
}
