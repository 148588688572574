import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { ButtonType } from "../button.type";
import { ButtonOutput } from "../button-output";
import { ButtonActionsType } from "../button-actions.type";

@Component({
  selector: "app-big-button",
  templateUrl: "./big-button.component.html",
  styleUrls: ["./big-button.component.scss"],
})
export class BigButtonComponent {
  /**
   * Text that should be displayed on the button
   */
  @Input() label = "";

  /**
   * Visual hierarchy for the button
   */
  @Input() buttonType: ButtonType = ButtonType.Primary;

  /**
   * Disabled state of the button
   */
  @Input() disabled: boolean = false;

  @HostBinding("class") get hostClasses() {
    const classList = ["big-button", "big-button--" + this.buttonType];
    if (this.disabled) {
      classList.push("button--disabled");
    }
    return classList;
  }

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostListener("click", ["$event"])
  public click(evt: Event) {
    this.action.emit({
      type: ButtonActionsType.Click,
    });
  }
}
