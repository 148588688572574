import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AppStore } from "../store/appStore";
import { Store } from "@ngrx/store";
import { UserPreferenceType } from "../store/user/user.type";
import { GetDashboardLoadingStatus } from "../store/user/user.selector";
import { APP_INIT_STATES } from "../store/status/status";

@Injectable({
  providedIn: "root",
})
export class VersionService {
  private differentVersion = false;

  constructor(private _store: Store<AppStore>) {
    this.differentVersion = this.checkVersion();

    if (this.differentVersion) {
      this.checkAuthentication();
    }
  }

  private checkAuthentication() {
    this._store
      .select(GetDashboardLoadingStatus)
      .subscribe((dashboardLoadingStatus) => {
        if (
          this.differentVersion &&
          dashboardLoadingStatus === APP_INIT_STATES.SUCCESS
        ) {
          this.differentVersion = false;
          localStorage.setItem("version", environment.version);
          this.runMigration();
        }
      });
  }

  private runMigration() {
    console.log("run migration for " + environment.version);

    console.log({
      id: UserPreferenceType.MedifinderPushNotifications,
      value: true,
    });

    switch (environment.version) {
      case "1.1.6 (2)":
        try {
          if (
            localStorage.getItem(
              "apoapp-dev-kammer-app-umfrage-questionnaire-hide-until"
            ) !== null
          ) {
            localStorage.setItem(
              "apoapp-dev-kammer-app-umfrage-questionnaire-hide-until",
              new Date().getTime().toString()
            );
          }
        } catch (e) {
          console.error("error resetting questionnaire hide until");
        }
        break;
      default:
        break;
    }

    localStorage.setItem("version", environment.version);

    console.log("migration done");
  }

  //check localstorage for current app version and compare it with the current version
  private checkVersion(): boolean {
    const currentVersion = localStorage.getItem("version");
    console.log(currentVersion);
    if (currentVersion !== environment.version) {
      return true;
    }
    return false;
  }
}
