import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { EntityTypesId } from "../../../io/entity/types/entityTypes";

import { EntityService } from "../../../io/entity/entity.service";
import { selectApoDepartment } from "../../../store/apo-department/apo-department.selector";
import { transformPhoneNumber } from "../../../helper/helper-functions";

@Component({
  selector: "app-department-footer",
  templateUrl: "./department-footer.component.html",
  styleUrls: ["./department-footer.component.scss"],
})
export class DepartmentFooterComponent implements OnInit {
  /**
   * Info that gets displayed in the speech bubble
   */
  @Input() info: string = "";

  /**
   * Department Name that gets displayed
   */
  @Input() department: string = "";

  /**
   * Telephone number of department
   */
  @Input() phoneNumber: string = "";

  /**
   * Emai address of department
   */
  @Input() emailAddress: string = "";

  @Input() id: Array<{ id: string }>;

  public departments: Array<any>;

  @HostBinding("class") get hostClasses() {
    return ["department-footer"];
  }

  constructor(private _entityService: EntityService) {}

  ngOnInit() {
    if (Array.isArray(this.id)) {
      this.departments = this._entityService.getMultipleEntities(
        EntityTypesId.ApoDepartment,
        this.id.map((id) => id.id),
        selectApoDepartment
      );
    }
  }

  protected readonly transformPhoneNumber = transformPhoneNumber;
}
