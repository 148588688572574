import { createAction, props } from "@ngrx/store";

export const enum VIEW_ACTIONS {
  CANCEL_LOAD_VIEW = "[VIEW] CANCEL_LOAD_VIEW",
}

export const loadView = createAction(
  "[View] Load View",
  props<{ url: string }>()
);

export const loadViewError = createAction(
  "[View] Load View Error",
  props<{ url: string; error: any }>()
);

export const cancelLoadView = createAction(VIEW_ACTIONS.CANCEL_LOAD_VIEW);
