import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AnimationBaseComponent } from "../../animations/animation-base/animation-base.component";
import { NavigationService } from "../../services/navigation.service";
import { ButtonOutput } from "../../ui/button/button-output";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { BasePageAnimationDirection } from "../../animations/animation-base/animation.types";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { first, Subscription } from "rxjs";
import {
  GetDashboardLoadingStatus,
  GetUserId,
} from "../../store/user/user.selector";
import { Store } from "@ngrx/store";
import { AppStore } from "../../store/appStore";
import { APP_INIT_STATES } from "../../store/status/status";
import { ViewHelperService } from "../../io/entity/view-helper/view-helper.service";

@Component({
  selector: "app-generic-overview",
  templateUrl: "./generic-overview.component.html",
  styleUrls: ["./generic-overview.component.scss"],
})
export class GenericOverviewComponent
  extends AnimationBaseComponent
  implements OnDestroy, OnInit
{
  public leftSwipePosition = 0;
  protected updateTimeout: number;

  protected _appActiveSubscription: Subscription;
  protected _subscriptions: Array<Subscription> = [];

  protected _dashboardLoadingStatusSub: any;

  protected _userID: any;
  protected _userIdSet = false;
  protected _initFilterSet = false;

  public targetElement: HTMLHtmlElement | null;
  public enablePullToRefresh = false;

  constructor(
    protected override _navigationService: NavigationService,
    protected _changeDetectorRef: ChangeDetectorRef,
    protected _store: Store<AppStore>,
    protected _viewHelperService: ViewHelperService
  ) {
    super(_navigationService);

    this.updateView();
  }

  updateView() {
    this._changeDetectorRef.markForCheck();

    this.updateTimeout = setTimeout(() => {
      this.updateView();
    }, 5000);
  }

  public SetEvent(
    event: ButtonOutput,
    routeType?: DashboardRoutesType,
    id?: number | string | undefined,
    additionalParameters?: any | undefined
  ) {
    // first check which button action type
    switch (event.type) {
      case ButtonActionsType.Click:
        if (routeType) {
          // navigate to url based on route type and add id if existing
          this._navigationService.navigateByUrl(
            routeType + (id || ""),
            BasePageAnimationDirection.LEFT,
            additionalParameters
          );
        }
        break;
      case ButtonActionsType.Back:
        if (routeType) {
          // navigate to url based on route type and add id if existing
          this._navigationService.navigateByUrl(
            routeType + (id || ""),
            BasePageAnimationDirection.RIGHT
          );
        }
        break;
    }
  }

  protected onUserSet() {}

  override ngOnDestroy() {
    this._subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });

    try {
      this._appActiveSubscription.unsubscribe();
    } catch (e) {}

    try {
      clearTimeout(this.updateTimeout);
    } catch (e) {}

    super.ngOnDestroy();
  }

  ngOnInit(): void {
    // check dashboard call loading status -> load news posts only when dashboard call is finished
    // (because we need the user ID, and we need to be sure that the user roles are already loaded too)
    this._dashboardLoadingStatusSub = this._store
      .select(GetDashboardLoadingStatus)
      .subscribe((dashboardLoadingStatus) => {
        if (dashboardLoadingStatus === APP_INIT_STATES.SUCCESS) {
          this._store
            .select(GetUserId)
            .pipe(first())
            .subscribe((id) => {
              this._userID = id;
              this._userIdSet = true;

              this.onUserSet();
            });

          if (this._dashboardLoadingStatusSub) {
            this._dashboardLoadingStatusSub.unsubscribe();
          } else {
            setTimeout(() => {
              this._dashboardLoadingStatusSub.unsubscribe();
            }, 1000);
          }
        }
      });

    this.targetElement = document.querySelector("html");
  }

  public track(index: number, item: { id: string }) {
    return item.id;
  }

  public BackToDashboard() {
    this.SetEvent(
      { type: ButtonActionsType.Back },
      DashboardRoutesType.Dashboard
    );
  }
}
