import { createAction, props } from "@ngrx/store";

export const enum ONBOARDING_ACTIONS {
  ONBOARDING_LOAD_SCREENS = "[USER] LOAD_SCREENS",
  ONBOARDING_LOAD_SCREENS_SUCCESS = "[USER] LOAD_SCREENS_SUCCESS",
  ONBOARDING_LOAD_SCREENS_ERROR = "[USER] LOAD_SCREENS_ERROR",
}

export const loadOnboardingScreens = createAction(ONBOARDING_ACTIONS.ONBOARDING_LOAD_SCREENS);

export const loadOnboardingScreensSuccess = createAction(
    ONBOARDING_ACTIONS.ONBOARDING_LOAD_SCREENS_SUCCESS,
    props<{
        response: any;
    }>()
);

export const loadOnboardingScreensError = createAction(
    ONBOARDING_ACTIONS.ONBOARDING_LOAD_SCREENS_ERROR,
    props<{
        error: any;
    }>()
);
