import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { BaseModalComponent } from "../base-modal/base-modal.component";
import { ModalType } from "../base-modal/modal.type";
import {
  filterModalAnimationsEnterLeave,
  filterModalAnimationsEnterLeaveFade,
} from "../filter-modal/filter-modal.animations";
import { ButtonType } from "../../button/button.type";
import { BasePageAnimationDirection } from "../../../animations/animation-base/animation.types";
import { DashboardRoutesType } from "../../../content/dashboard/dashboard-routes.type";
import { NavigationService } from "../../../services/navigation.service";
import { Store } from "@ngrx/store";
import { approveOrRejectChamberInfo } from "../../../store/approval/approval.actions";
import {
  getActionUrisFromApprovalPost,
  getModerationStatusById,
  getNextInfoForApproval,
} from "../../../store/approval/approval.selector";
import { Subject, Subscription, takeUntil } from "rxjs";
import { ModerationStateUpdateStatus } from "../../../store/news-post/news-post";
import { Actions } from "@ngrx/effects";

@Component({
  selector: "app-approval-modal",
  templateUrl: "./approval-modal.component.html",
  styleUrls: ["./approval-modal.component.scss"],
  animations: [
    filterModalAnimationsEnterLeave,
    filterModalAnimationsEnterLeaveFade,
  ],
})
export class ApprovalModalComponent
  extends BaseModalComponent
  implements OnChanges, OnDestroy
{
  @Input() id: string = "";
  @Input() type: ModalType | undefined = ModalType.Decline;

  @Input() toApproveNumber: Number = 0;

  @Output() approvalAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ModalType = ModalType;
  public ButtonType = ButtonType;
  public declineReason: string = "";
  public DashboardRoutesType = DashboardRoutesType;
  private _getActionUrisFromApprovalPostKiller$ = new Subject();
  public ModerationStatus = ModerationStateUpdateStatus;
  public moderationStatus$: any;
  private _moderationStatus: any;
  public nextPostPath: any;
  private _nextPostSubscription: Subscription;

  private approvalSubscription: Subscription;
  private moderationStateSubscription: Subscription;

  public preDecline = true;

  constructor(
    private _navigationService: NavigationService,
    private _store: Store,
    private _actions$: Actions,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["open"].currentValue === true &&
      changes["type"] &&
      changes["type"].currentValue === this.ModalType.Approve
    ) {
      this.ApproveOrDecline(true);
    }
    if (changes["id"].currentValue !== undefined) {
      this.nextPostPath = undefined;
      try {
        this._nextPostSubscription.unsubscribe();
      } catch (e) {}

      this._nextPostSubscription = this._store
        .select(getNextInfoForApproval(this.id))
        .subscribe((post) => {
          if (post && post.entity) {
            this.nextPostPath =
              DashboardRoutesType.NewsDetail + post.entity.entity_uuid;
          }
        });
    }
  }

  public SetEvent(event: any, routeType: DashboardRoutesType, path?: string) {
    switch (routeType) {
      case DashboardRoutesType.NewsDetail:
        if (path) {
          this._navigationService.navigateByUrl(
            path,
            BasePageAnimationDirection.UP
          );
        }
        break;
      default:
        this._navigationService.navigateByUrl(
          routeType,
          BasePageAnimationDirection.RIGHT
        );
        break;
    }
  }

  public ApproveOrDecline(approve = false) {
    this.approvalSubscription = this._store
      .select(getActionUrisFromApprovalPost(this.id))
      .pipe(takeUntil(this._getActionUrisFromApprovalPostKiller$))
      .subscribe((actionUris) => {
        let actionUrl = "";
        if (approve) {
          if (actionUris && actionUris.approve) {
            actionUrl = actionUris.approve;
          }
        } else {
          if (actionUris && actionUris.reject) {
            actionUrl = actionUris.reject;
          }
        }

        if (actionUris) {
          this._getActionUrisFromApprovalPostKiller$.next(true);
        }
        let reason = approve === false ? this.declineReason : "";

        if (actionUrl !== "") {
          this._store.dispatch(
            approveOrRejectChamberInfo({
              id: this.id,
              reason: reason,
              actionURI: actionUrl,
            })
          );
          this._changeDetectorRef.markForCheck();
          this._changeDetectorRef.detectChanges();
        }

        this.moderationStatus$ = this._store.select(
          getModerationStatusById(this.id)
        );

        this.moderationStateSubscription = this.moderationStatus$.subscribe(
          (status: any) => {
            this._moderationStatus = status;
            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
          }
        );

        this.approvalAction.emit(approve);

        this.preDecline = false;
      });
  }

  public Decline(event: any) {
    if (this.declineReason && this.declineReason !== "") {
      this.ApproveOrDecline(false);
    }
  }

  public override Close() {
    if (!this.locked) {
      this.open = false;
      this.openChange.emit(this.open);

      if (
        this._moderationStatus === ModerationStateUpdateStatus.SUCCESS ||
        this._moderationStatus === ModerationStateUpdateStatus.ERROR
      ) {
        console.log("xxxx");
        this._navigationService.navigateByUrl(
          DashboardRoutesType.NewsOverview,
          BasePageAnimationDirection.RIGHT
        );
      } else {
        this.type = undefined;
      }
    }
  }

  ngOnDestroy() {
    try {
      if (this.approvalSubscription) {
        this.approvalSubscription.unsubscribe();
      }
    } catch (e) {}

    try {
      if (this.moderationStateSubscription) {
        this.moderationStateSubscription.unsubscribe();
      }
    } catch (e) {}
  }
}
