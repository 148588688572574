import { Component } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-subscription-manager",
  template: "",
  styleUrls: [],
})
export class SubscriptionManagerComponent {
  public _subscriptions: Array<Subscription>;

  constructor() {
    this._subscriptions = [];
  }

  /**
   * Add a subscription to the subscription array. All subscriptions get unsubscribed when the component gets destroyed
   * @param subscription
   */
  public addSubscription(subscription: any) {
    this._subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
