import { Inject, Injectable, NgZone } from "@angular/core";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { DeviceService } from "./device/device.service";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { NavigationService } from "../services/navigation.service";
import { BasePageAnimationDirection } from "../animations/animation-base/animation.types";
import { AlertService } from "../alerts/alert.service";
import { Actions, ofType } from "@ngrx/effects";
import { loadDashboardSuccess } from "../store/user/user.actions";
import { Badge } from "@capawesome/capacitor-badge";
import { fetchApprovalMetaDataSuccess } from "../store/approval/approval.actions";
import { ViewService } from "./entity/view.service";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  private _token: string;
  private _requested = false;

  private _ownRejectedCount = 0;
  private _toApproveCount = 0;

  get requested(): boolean {
    return this._requested;
  }

  get token(): string {
    return this._token;
  }

  constructor(
    private _deviceService: DeviceService,
    private _router: Router,
    private _navigationService: NavigationService,
    private _alertService: AlertService,
    private _actions$: Actions,
    private _viewService: ViewService,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {
    // @ts-ignore
    window["push"] = (notification: ActionPerformed) => {
      this.pushNotificationActionPerformed(notification);
    };

    this.addBadgeListener();

    if (!this.isAvailable()) return;

    this.addListener();
  }

  public isAvailable() {
    return Capacitor.isPluginAvailable("PushNotifications");
  }

  public checkPermissions() {
    if (this._requested) return;
    PushNotifications.checkPermissions().then((result) => {
      PushNotifications.register()
        .then((registerResult) => {})
        .catch((error) => {});
    });
  }

  public requestPermissions() {
    if (!this.isAvailable() && !this._requested) return;

    this._requested = true;

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()
          .then((res) => {})
          .catch((res) => {
            console.error(res);
          });
      }
    });
  }

  private addListener() {
    PushNotifications.addListener("registration", (token: Token) => {
      this._token = token.value;
      this._deviceService.pushNotificationToken = this._token;
    });

    PushNotifications.addListener("registrationError", (error: any) => {
      //alert("Error on registration: " + JSON.stringify(error));
      console.error(error);
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        if (Capacitor.getPlatform() === "android") {
          PushNotifications.removeAllDeliveredNotifications().then((res) => {});

          this._alertService.DispatchInfo(
            notification.title + "",
            notification.body + "",
            20,
            {
              action: "push_notification_click",
              notification: notification,
            }
          );
        }
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        this.pushNotificationActionPerformed(notification);
      }
    );
  }

  public pushNotificationActionPerformed(notification: ActionPerformed) {
    if (notification.actionId === "tap") {
      //PushNotifications.removeAllDeliveredNotifications().then((res) => {});
      this._viewService.unsetActiveViewHelper();

      switch (notification.notification.data["entrypoint"]) {
        case "entity_approved":
        case "entity":
        case "entity_approval_required":
          switch (notification.notification.data["entity_bundle"]) {
            case "news_post":
              let url =
                "/app/kammerinfo/" +
                notification.notification.data["entity_uuid"];

              if (String(window.location.pathname) != String(url)) {
                this.zone.run(() => {
                  this._navigationService.navigateByUrl(url);
                });
              } else {
                window.location.reload();
              }
              break;
            case "apo_medication_search":
              let urlMedi =
                "/app/medifinder/" +
                notification.notification.data["entity_uuid"];

              if (String(window.location.pathname) != String(urlMedi)) {
                this.zone.run(() => {
                  this._navigationService.navigateByUrl(urlMedi);
                });
              } else {
                window.location.reload();
              }
              break;
          }

          break;
        case "entity_approval_expired":
          this.zone.run(() => {
            this._navigationService.navigateByUrl(
              "/app/info/entity_approval_expired",
              BasePageAnimationDirection.FADE,
              { info: "entity_approval_expired" }
            );
          });

          break;
        default:
          break;
      }
    }
  }

  private addBadgeListener() {
    this._actions$
      .pipe(ofType(loadDashboardSuccess.type))
      .subscribe((payload) => {
        try {
          this._ownRejectedCount = payload["response"]["news_posts"][
            "own_rejected_count"
          ]
            ? parseInt(payload["response"]["news_posts"]["own_rejected_count"])
            : 0;
        } catch (e) {
          this._ownRejectedCount = 0;
        }
        this.updateBadgeCount();
      });

    this._actions$
      .pipe(ofType(fetchApprovalMetaDataSuccess.type))
      .subscribe((payload: { response: { approval_required: [] } }) => {
        try {
          this._toApproveCount = payload["response"]["approval_required"]
            ? payload["response"]["approval_required"].length
            : 0;
          this.updateBadgeCount();
        } catch (e) {}
      });
  }

  private async updateBadgeCount() {
    try {
      await Badge.set({
        count: parseInt(String(this._ownRejectedCount + this._toApproveCount)),
      });
      const result = await Badge.get();
    } catch (e) {
      console.error(e);
    }
  }
}
