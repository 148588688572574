import * as fromEntities from "./entity.reducer";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import { EntityTypesId } from "../types/entityTypes";
import { Entity } from "../types/entity";
import cloneDeep from "lodash-es/cloneDeep";
import { GetUser } from "../../../store/user/user.selector";
import { ModerationSate } from "../../../store/news-post/news-post";

export interface State {
  users: fromEntities.State;
}

export const reducers: ActionReducerMap<State> = {
  users: fromEntities.EntityReducer,
};

export const selectEntityState =
  createFeatureSelector<fromEntities.State>("entity");

export const selectEntityIds = createSelector(
  selectEntityState,
  fromEntities.selectIds
);

export const selectAllEntities = createSelector(
  selectEntityState,
  fromEntities.selectAll
);

export const selectEntitiesByType = (type: EntityTypesId) =>
  createSelector(selectAllEntities, (entities): Array<Entity> => {
    return entities.filter((entity) => entity.type === type);
  });

export const selectEntity = (id: string) =>
  createSelector(selectAllEntities, GetUser, (entities, user) => {
    const selectedEntity = cloneDeep(
      entities.find((entity) => entity.id === id)
    );
    // search in user store for the ids which are currently unread and set is_read state in entity, but only if type is news_post
    if (selectedEntity && selectedEntity.type === EntityTypesId.NewsPost) {
      selectedEntity.attributes.is_read = !user.unreadNewsPosts.find(
        (unread) => unread === selectedEntity.id
      );
    }
    return selectedEntity;
  });

export const selectEntitiesByIds = (ids: Array<string>) =>
  createSelector(selectAllEntities, (entities): Array<Entity> => {
    return cloneDeep(entities.filter((entity) => ids.includes(entity.id)));
  });

export const selectNewsPostEntitiesByIds = (ids: Array<string>) =>
  createSelector(
    selectAllEntities,
    GetUser,
    (entities, user): Array<Entity> => {
      const entitiesWithReadStatus = cloneDeep(
        entities.filter(
          (entity) =>
            ids.includes(entity.id) && entity.type === EntityTypesId.NewsPost
        )
      );
      if (entitiesWithReadStatus) {
        entitiesWithReadStatus.forEach((entity) => {
          // search in user store for the ids which are currently unread and set is_read state for entities, but only if type is news_post
          entity.attributes.is_read = !user.unreadNewsPosts.find(
            (unread) => unread === entity.id
          );
        });
      }
      return entitiesWithReadStatus;
    }
  );

export const selectEntityIdsForDeletion = () =>
  createSelector(selectAllEntities, (entities): Array<any> | undefined => {
    let idsToDelete = [];
    for (const [key, value] of Object.entries(entities)) {
      if (value?.attributes.ready_for_deletion === true) {
        idsToDelete.push(value.id);
      }
    }

    if (idsToDelete.length > 0) {
      return idsToDelete;
    } else {
      return undefined;
    }
  });

export const selectEntitiesByTypeAndModerationState = (
  type: EntityTypesId,
  moderationState: ModerationSate
) =>
  createSelector(selectEntitiesByType(type), (entities): Array<Entity> => {
    return cloneDeep(
      entities.filter(
        (entity) => entity.attributes.moderation_state === moderationState
      )
    );
  });
