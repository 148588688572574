import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-input-text-area",
  templateUrl: "./input-text-area.component.html",
  styleUrls: ["./input-text-area.component.scss"],
})
export class InputTextAreaComponent {
  /**
   * Text that should be displayed above the input
   */
  @Input() label: String = "";

  /**
   * main output
   */
  @Output() currentValue: EventEmitter<String> = new EventEmitter<String>();

  public value: String = "";

  public valueChanged() {
    this.currentValue.emit(this.value);
  }
}
