import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, take } from "rxjs";
import { AuthService } from "../store/status/auth/auth.service";
import { Capacitor } from "@capacitor/core";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _keycloakService: KeycloakService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (Capacitor.getPlatform() === "web") {
      return true;
    } else {
      return new Observable((obs) => {
        this._authService
          .isAuthenticated(true)
          .pipe(take(1))
          .subscribe((result) => {
            if (result) {
              obs.next(true);
              obs.complete();
            } else if (this._authService.initialRequest && !result) {
              this._router.navigateByUrl(
                "/login?redirect=" + encodeURIComponent(state.url)
              );
              obs.next(false);
              obs.complete();
            } else if (!result) {
              this._authService.authenticate(obs);
            }
          });
      });
    }
  }
}
