import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  NgZone,
  OnInit,
} from "@angular/core";
import { AppStore } from "./store/appStore";
import { Store } from "@ngrx/store";
import { ChangeAppInitStatus } from "./store/status/store/status.actions";
import { APP_INIT_STATES } from "./store/status/status";
import { PushNotificationService } from "./io/push-notification.service";
import { DeviceService } from "./io/device/device.service";
import { ConfigService } from "./config/config.service";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { ViewService } from "./io/entity/view.service";
import { AccessService } from "./services/access.service";
import { BiometricAccessService } from "./services/biometric-access.service";
import { NavigationEnd, Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { environment } from "../environments/environment";
import { Environments } from "../environments/environments";
import { VersionService } from "./services/version.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @HostBinding("style.--safe-area-top") safeAreaTop: string = "";
  @HostBinding("style.display") display: string = "block !important";

  public biometricCheck: boolean;
  public isSecuredPath = false;

  constructor(
    private _store: Store<AppStore>,
    private _pushNotificationService: PushNotificationService,
    private _deviceService: DeviceService,
    private _configService: ConfigService,
    private _viewService: ViewService,
    private _accessService: AccessService,
    private _biometricAccessService: BiometricAccessService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private zone: NgZone,
    private _versionService: VersionService
  ) {
    this._accessService.loadAndProcessAccessList(true);

    this._store.dispatch(
      ChangeAppInitStatus({ state: APP_INIT_STATES.DEFAULT })
    );

    this.info().then((di) => {
      switch (Capacitor.getPlatform()) {
        case "web":
        case "android":
          this.safeAreaTop = "0px";
          break;
        case "ios":
          this.safeAreaTop = this.hasDynamicIsland(di.model) ? "105px" : "30px";
          break;
      }
    });
  }

  public async info() {
    return await Device.getInfo();
  }

  private hasDynamicIsland(model: string) {
    console.log(model);
    return (
      ["iPhone15,3", "iPhone15,2", "iPhone16", "iPhone17", "iPhone18"].indexOf(
        model
      ) >= 0
    );
  }

  ngOnInit() {
    this._biometricAccessService.biometryCheckActive$.subscribe((active) => {
      this.biometricCheck = active;
      this._changeDetectorRef.detectChanges();
    });

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isSecuredPath =
          this._biometricAccessService.isBiometricSecuredPath(
            event.urlAfterRedirects
          );
        this._changeDetectorRef.detectChanges();
      }
    });

    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        if (event.url.indexOf("at.liechtenecker") > -1) {
          return;
        }

        const slug = event.url
          .split(
            environment.deployment === Environments.production ? ".at" : ".dev"
          )
          .pop();

        console.log(environment.deployment, slug, event.url);
        if (slug) {
          this._router.navigateByUrl(slug).catch((reason) => {
            console.error("Could not route to slug", slug, reason);
            this._router.navigateByUrl("/app/dashboard");
          });
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }
}
