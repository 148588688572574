import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {SubscriptionManagerComponent} from "./helper/subscription-manager/subscription-manager.component";
import {Store, StoreModule} from "@ngrx/store";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi,} from "@angular/common/http";
import {AppStatusReducer} from "./store/status/store/status.reducer";
import {UserReducer} from "./store/user/user.reducer";

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UiModule} from "./ui/ui.module";

import {I18nModule} from "./i18n/i18n/i18n.module";

// Pages
import {DashboardComponent} from "./content/dashboard/dashboard.component";
import {InfoOverviewComponent} from "./content/info-overview/info-overview.component";
import {InfoDetailComponent} from "./content/info-detail/info-detail.component";
import {EventOverviewComponent} from "./content/event-overview/event-overview.component";
import {SettingsComponent} from "./content/settings/settings.component";

// Entity Store
import {EntityReducer} from "./io/entity/store/entity.reducer";
import {EffectsModule} from "@ngrx/effects";
import {EntityEffects} from "./io/entity/store/entity.effects";

import {AppStore} from "./store/appStore";

// Pipes
import {SafePipe} from "./pipes/safe.pipe";
import {EmbeddedContentPipe} from "./pipes/embedded-content.pipe";

// Locale
import localeDe from "@angular/common/locales/de-AT";
import {registerLocaleData} from "@angular/common";

import {LoadingComponent} from "./content/loading/loading.component";
import {OnboardingComponent} from "./content/onboarding/onboarding.component";
import {ViewEffects} from "./io/entity/store/view.effects";
import {UserEffects} from "./store/user/user.effects";
import {AuthReducer} from "./store/status/auth/store/auth.reducer";
import {AuthenticationInterceptor} from "./store/status/auth/authentication.interceptor";

// Onboarding Store
import {OnboardingReducer} from "./store/onboarding/onboarding.reducer";
import {OnboardingEffects} from "./store/onboarding/onboarding.effects";

import {AnimationBaseComponent} from "./animations/animation-base/animation-base.component";
import {LoginComponent} from "./content/login/login.component";
import {LoginErrorComponent} from "./content/login-error/login-error.component";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {ConfigService} from "./config/config.service";
import {Capacitor} from "@capacitor/core";
import {LogoutComponent} from "./content/logout/logout.component";
import {ApprovalReducer} from "./store/approval/approval.reducer";
import {ApprovalEffects} from "./store/approval/approval.effects";
import {RouteReuseStrategy} from "@angular/router";
import {CustomRouteReuseStrategy} from "./routing/customRouteReuseStrategy";
import {InfoPageComponent} from "./content/info-page/info-page.component";
import {AlertsReducer} from "./store/alerts/alerts.reducer";
import {AlertViewComponent} from "./alerts/alert-view/alert-view.component";
import {NgxPullToRefreshModule} from "ngx-pull-to-refresh";


import {environment} from "../environments/environment";
import {Environments} from "../environments/environments";
import {ViewService} from "./io/entity/view.service";
import {LoginBlacklistComponent} from "./content/login-blacklist/login-blacklist.component";
import {noAuthURIs} from "./store/status/auth/no-auth-uris";
import {BiometricAccessErrorComponent} from "./content/biometric-access-error/biometric-access-error.component";
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from "./io/timeout.interceptor";
import {ErrorPageComponent} from "./content/error-page/error-page.component";
import {EmailDataChangeComponent} from "./content/email-data-change/email-data-change.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MedifinderOverviewComponent} from "./content/medifinder-overview/medifinder-overview.component";
import {GenericOverviewComponent} from "./content/generic-overview/generic-overview.component";
import {GenericDetailComponent} from "./content/generic-detail/generic-detail.component";
import {MedifinderDetailComponent} from "./content/medifinder-detail/medifinder-detail.component";
import {PharmacyComponent} from "./content/parts/pharmacy/pharmacy.component";

import {EmailUnsubscribeComponent} from "./content/email-unsubscribe/email-unsubscribe.component";
import {EmailSelfServiceComponent} from "./content/email-self-service/email-self-service.component";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";

import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import {provideLottieOptions} from "ngx-lottie";

registerLocaleData(localeDe);

Bugsnag.start({
    apiKey: "8a3269d5d8cf73e7377b73510173dc29",
    appVersion: `${environment.version} - ${environment.deployment}`,
    releaseStage: environment.deployment,
    enabledReleaseStages: [
        Environments.production,
        Environments.rc,
        Environments.staging,
    ],
});

// create a factory which will return the BugSnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler()
}

function initializeKeycloak(
    keycloak: KeycloakService,
    configService: ConfigService
) {
    const config = configService.config;

    if (Capacitor.getPlatform() !== "web") {
        return () => {
        };
    }

    return () =>
        keycloak.init({
            config: {
                url: config.auth.url,
                realm: config.auth.realm,
                clientId: config.auth.clientId,
            },
            initOptions: {
                onLoad: "check-sso",
                checkLoginIframe: false,
            },
            shouldAddToken: (request) => {
                const {method, url} = request;

                if (
                    noAuthURIs.find((e) => {
                        return request.url.includes(e);
                    })
                ) {
                    return false;
                }

                return url.indexOf(config.cms.url) !== -1;
            },
        });
}

@NgModule({
    declarations: [
        AppComponent,
        SubscriptionManagerComponent,
        DashboardComponent,
        InfoOverviewComponent,
        InfoDetailComponent,
        EventOverviewComponent,
        SafePipe,
        SettingsComponent,
        EmbeddedContentPipe,
        OnboardingComponent,
        AnimationBaseComponent,
        LoginComponent,
        LoginErrorComponent,
        LogoutComponent,
        InfoPageComponent,
        AlertViewComponent,
        LoginBlacklistComponent,
        BiometricAccessErrorComponent,
        ErrorPageComponent,
        EmailDataChangeComponent,
        MedifinderOverviewComponent,
        GenericOverviewComponent,
        GenericDetailComponent,
        MedifinderDetailComponent,
        PharmacyComponent,
        EmailSelfServiceComponent,
        EmailUnsubscribeComponent,
    ],
    bootstrap: [AppComponent],
    exports: [LoadingComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        EffectsModule.forRoot([
            EntityEffects,
            ViewEffects,
            UserEffects,
            OnboardingEffects,
            ApprovalEffects,
        ]),
        StoreModule.forRoot(
            {
                appStatus: AppStatusReducer,
                entity: EntityReducer,
                user: UserReducer,
                auth: AuthReducer,
                onboarding: OnboardingReducer,
                approval: ApprovalReducer,
                alerts: AlertsReducer,
            },
            {}
        ),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
        }),
        I18nModule,
        UiModule,
        BrowserAnimationsModule,
        KeycloakAngularModule,
        NgxPullToRefreshModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    providers: [
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        { provide: ErrorHandler, useFactory: errorHandlerFactory } ,
{provide: LOCALE_ID, useValue: "de-AT"},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        {provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true},
        {provide: DEFAULT_TIMEOUT, useValue: 30000},
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService, ConfigService],
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy,
        },
        provideHttpClient(withInterceptorsFromDi()),


    ],
})
export class AppModule {
    private appGettingActiveListener = false;

    constructor(
        private _store: Store<AppStore>,
        private _viewService: ViewService
    ) {
    }
}
