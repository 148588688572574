import { createSelector } from "@ngrx/store";
import { selectEntitiesByIds } from "../../io/entity/store/entity.selector";
import { GetUserUUID } from "../user/user.selector";
import { Entity, MediFinderEntity } from "../../io/entity/types/entity";

export const selectMediFinderSearchesByIds = (ids: Array<string>) =>
  createSelector(
    selectEntitiesByIds(ids),
    GetUserUUID,
    (entities: Entity[], userUUID) => {
      return (entities as MediFinderEntity[])
        .map((entity) => {
          try {
            entity.attributes.own =
              entity.relationships.owner_uid.data.id === userUUID;
          } catch (e) {}
          return entity;
        })
        .sort(
          (a, b) =>
            new Date(b.attributes.created).getTime() -
            new Date(a.attributes.created).getTime()
        );
    }
  );

export const selectMediFinderSearchById = (id: string) =>
  createSelector(selectMediFinderSearchesByIds([id]), (entities) => {
    return entities[0];
  });
