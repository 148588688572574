import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { ButtonOutput } from "../../button/button-output";
import { ButtonActionsType } from "../../button/button-actions.type";
import { DashboardCardType } from "./dashboard-card.type";
import { Event } from "../../../store/user/user";
import { dateTimeEvent, dateYear } from "../../../config/units";
import { ConfigService } from "../../../config/config.service";

@Component({
  selector: "app-dashboard-card",
  templateUrl: "./dashboard-card.component.html",
  styleUrls: ["./dashboard-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardCardComponent implements OnInit {
  /**
   * Title of the dashboard card
   */
  @Input() title: string = "";

  /**
   * Text of the dashboard card
   */
  @Input() text: string = "";

  /**
   * event string for additional caption
   */
  @Input() event: Event | undefined = undefined;

  /**
   * indicates which icon should be used
   */
  @Input() type: DashboardCardType = DashboardCardType.Infos;

  public DashboardCardType = DashboardCardType;
  public caption = "";
  public dateTimeEvent = dateTimeEvent;
  public dateYear = dateYear;
  public publicFilesURL: string;

  @HostBinding("class") get hostClasses() {
    const classList = ["box"];
    if (this.type === DashboardCardType.Event) {
      classList.push("event");

      if (!this.event?.field_lineupr_iframe_link) {
        classList.push("no-click");
      }
    } else {
      classList.push(this.type);
    }
    return classList;
  }

  @HostBinding("attr.tabindex") tabindex = "0";

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostListener("click", ["$event"])
  public click(evt: Event) {
    this.action.emit({
      type: ButtonActionsType.Click,
    });
  }

  constructor(private _configService: ConfigService) {
    this.publicFilesURL = this._configService.config.cms.url;
  }

  ngOnInit(): void {}

  protected readonly undefined = undefined;
}
