import { createAction, props } from "@ngrx/store";

export const enum APPROVAL_ACTIONS {
  APPROVAL_FETCH_META_DATA = "[APPROVAL] FETCH_META_DATA",
  APPROVAL_FETCH_META_DATA_SUCCESS = "[APPROVAL] FETCH_META_DATA_SUCCESS",
  APPROVAL_FETCH_META_DATA_ERROR = "[APPROVAL] FETCH_META_DATA_ERROR",

  APPROVAL_APPROVE_CHAMBER_INFO = "[APPROVAL] APPROVE_CHAMBER_INFO",
  APPROVAL_APPROVE_CHAMBER_INFO_SUCCESS = "[APPROVAL] APPROVE_CHAMBER_INFO_SUCCESS",
  APPROVAL_APPROVE_CHAMBER_INFO_ERROR = "[APPROVAL] APPROVE_CHAMBER_INFO_ERROR",

  APPROVAL_DECLINE_CHAMBER_INFO = "[APPROVAL] APPROVE_CHAMBER_INFO",
  APPROVAL_DECLINE_CHAMBER_INFO_SUCCESS = "[APPROVAL] APPROVE_CHAMBER_INFO_SUCCESS",
  APPROVAL_DECLINE_CHAMBER_INFO_ERROR = "[APPROVAL] APPROVE_CHAMBER_INFO_ERROR",
}

export const fetchApprovalMetaData = createAction(
  APPROVAL_ACTIONS.APPROVAL_FETCH_META_DATA
);
export const fetchApprovalMetaDataSuccess = createAction(
  APPROVAL_ACTIONS.APPROVAL_FETCH_META_DATA_SUCCESS,
  props<{ response: any }>()
);
export const fetchApprovalMetaDataError = createAction(
  APPROVAL_ACTIONS.APPROVAL_FETCH_META_DATA_ERROR,
  props<{ response: any }>()
);

export const approveOrRejectChamberInfo = createAction(
  APPROVAL_ACTIONS.APPROVAL_APPROVE_CHAMBER_INFO,
  props<{
    id: string;
    actionURI: string;
    reason?: string;
  }>()
);
export const approveOrRejectChamberInfoSuccess = createAction(
  APPROVAL_ACTIONS.APPROVAL_APPROVE_CHAMBER_INFO_SUCCESS,
  props<{
    id: string;
    response: any;
    reason?: string;
  }>()
);

export const approveOrRejectChamberInfoError = createAction(
  APPROVAL_ACTIONS.APPROVAL_APPROVE_CHAMBER_INFO_ERROR,
  props<{
    id: string;
    error: any;
  }>()
);

export const declineChamberInfo = createAction(
  APPROVAL_ACTIONS.APPROVAL_APPROVE_CHAMBER_INFO,
  props<{
    id: string;
    reason: string;
    actionURI: string;
  }>()
);
