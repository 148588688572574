import { animate, style, transition, trigger } from "@angular/animations";

export const headerAnimationsEnterLeave = trigger("enterLeave", [
  transition(":enter", [
    style({ opacity: 0, transform: "translateY(-100%)" }),
    animate(
      "250ms cubic-bezier(.4, 0, .2, 1)",
      style({ opacity: 1, transform: "translateY(0%)" })
    ),
  ]),
  transition(":leave", [
    animate(
      "250ms cubic-bezier(.4, 0, .2, 1)",
      style({ opacity: 0, transform: "translateY(-100%)" })
    ),
  ]),
]);
