import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { ButtonOutput } from "../button-output";
import { ButtonActionsType } from "../button-actions.type";
import { Event } from "../../../store/user/user";

@Component({
  selector: "app-filter-button",
  templateUrl: "./filter-button.component.html",
  styleUrls: ["./filter-button.component.scss"],
})
export class FilterButtonComponent {
  /**
   * Text that should be displayed on the button
   */
  @Input() label = "";

  /**
   * Text that should be displayed before label
   */
  @Input() preLabel: string = "";

  /**
   * Active State of the button
   */
  @Input() active: boolean = false;

  /**
   * ID of the button
   */
  @Input() id: number;

  /**
   * Disabled state of the button
   */
  @Input() disabled: boolean = false;

  @HostBinding("class") get hostClasses() {
    const classList = ["filter-button"];
    if (this.active) {
      classList.push("filter-button--active");
    }
    if (this.disabled) {
      classList.push("button--disabled");
    }
    return classList;
  }

  @HostBinding("attr.tabindex") tabindex = "0";

  /**
   * main button output
   */
  @Output() action: EventEmitter<ButtonOutput> =
    new EventEmitter<ButtonOutput>();

  @HostListener("click", ["$event"])
  public click(evt: Event) {
    this.active = !this.active;
    this.action.emit({
      type: ButtonActionsType.Click,
      data: {
        label: this.label,
        active: this.active,
        id: this.id,
      },
    });
  }
}
