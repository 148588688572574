import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ResponseStructureError } from "../../error/types/response-structure-error";
import { EMPTY } from "rxjs";
import { Store } from "@ngrx/store";
import { AppStore } from "../appStore";
import { ApiConnectorDashboardService } from "../dashboard/api-connector-dashboard.service";
import {
  loadOnboardingScreens,
  loadOnboardingScreensSuccess,
} from "./onboarding.actions";

@Injectable()
export class OnboardingEffects {
  loadOnboardingScreens$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadOnboardingScreens),
      mergeMap(() => {
        return this._dashboardApi.loadOnboardingScreens().pipe(
          map((payload) => {
            try {
              return loadOnboardingScreensSuccess({ response: payload });
            } catch (e) {
              throw ResponseStructureError(e);
            }
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<AppStore>,
    private _dashboardApi: ApiConnectorDashboardService
  ) {}
}
