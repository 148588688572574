import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BaseModalComponent} from "./modal/base-modal/base-modal.component";
import {ButtonComponent} from "./button/button/button.component";
import {FilterButtonComponent} from "./button/filter-button/filter-button.component";
import {IconButtonComponent} from "./button/icon-button/icon-button.component";
import {SvgIconComponent} from "./svg-icon/svg-icon.component";
import {BigButtonComponent} from "./button/big-button/big-button.component";
import {TextButtonComponent} from "./button/text-button/text-button.component";
import {ProfileIconComponent} from "./profile-icon/profile-icon.component";
import {TabItemComponent} from "./tab-item/tab-item.component";
import {MessageBoxComponent} from "./box/message-box/message-box.component";
import {AdviceCardComponent} from "./box/advice-card/advice-card.component";
import {DashboardCardComponent} from "./box/dashboard-card/dashboard-card.component";
import {InfoCardComponent} from "./box/info-card/info-card.component";
import {HeaderComponent} from "./header/header/header.component";
import {I18nModule} from "../i18n/i18n/i18n.module";
import {BackHeaderComponent} from "./header/back-header/back-header.component";
import {InputSearchTextComponent} from "./input/input-search-text/input-search-text.component";
import {FormsModule} from "@angular/forms";
import {MediaImageComponent} from "./drupal/media-image/media-image/media-image.component";
import {SpeechBubbleComponent} from "./box/speech-bubble/speech-bubble.component";
import {DepartmentFooterComponent} from "./footer/department-footer/department-footer.component";
import {FilterModalComponent} from "./modal/filter-modal/filter-modal.component";
import {InputDateComponent} from "./input/input-date/input-date.component";
import {ApprovalModalComponent} from "./modal/approval-modal/approval-modal.component";
import {InputTextAreaComponent} from "./input/input-text-area/input-text-area.component";
import {TabsComponent} from "./tabs/tabs.component";
import {LoadingComponent} from "../content/loading/loading.component";
import player from "lottie-web";

import {SkeletonTextComponent} from "./skeleton/skeleton-text/skeleton-text.component";
import {NotificationBannerComponent} from "./button/notification-banner/notification-banner.component";
import {InputCheckboxComponent} from "./input/input-checkbox/input-checkbox.component";
import {FilterComponent} from "./button/filter/filter.component";
import {ImageButtonComponent} from "./button/image-button/image-button.component";
import {LoadMoreButtonComponent} from "./button/load-more-button/load-more-button.component";
import {FederalStateComponent} from "../content/parts/federal-state/federal-state.component";
import {AttachmentsComponent} from "./attachments/attachments.component";
import {FileSizePipe} from "../pipes/file-size.pipe";
import {SwipeDirective} from "./directive/swipe.directive";
import {CalloutComponent} from "./box/callout/callout.component";
import {InfoModalComponent} from "./modal/info-modal/info-modal.component";
import {InputTextComponent} from "./input/input-text/input-text.component";
import {MedifinderSearchCardComponent} from "./box/medifinder-search-card/medifinder-search-card.component";
import {
    NewMedifinderSearchModalComponent
} from "./modal/new-medifinder-search-modal/new-medifinder-search-modal.component";
import {InputDropdownComponent} from "./input/input-dropdown/input-dropdown.component";
import {PharmacyToDropdownPipe} from "./modal/new-medifinder-search-modal/pharmacy-to-dropdown.pipe";
import {MedifinderFilterModalComponent} from "./modal/medifinder-filter-modal/medifinder-filter-modal.component";
import {MedifinderInfoModalComponent} from "./modal/medifinder-info-modal/medifinder-info-modal.component";
import {LottieComponent} from "ngx-lottie";

function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        SvgIconComponent,
        BaseModalComponent,
        ButtonComponent,
        FilterButtonComponent,
        IconButtonComponent,
        BigButtonComponent,
        ProfileIconComponent,
        TabItemComponent,
        MessageBoxComponent,
        AdviceCardComponent,
        DashboardCardComponent,
        InfoCardComponent,
        HeaderComponent,
        TextButtonComponent,
        BackHeaderComponent,
        InputSearchTextComponent,
        MediaImageComponent,
        SpeechBubbleComponent,
        DepartmentFooterComponent,
        FilterModalComponent,
        InputDateComponent,
        ApprovalModalComponent,
        InputTextAreaComponent,
        TabsComponent,
        LoadingComponent,
        SkeletonTextComponent,
        NotificationBannerComponent,
        InputCheckboxComponent,
        FilterComponent,
        ImageButtonComponent,
        LoadMoreButtonComponent,
        FederalStateComponent,
        AttachmentsComponent,
        FileSizePipe,
        SwipeDirective,
        CalloutComponent,
        InfoModalComponent,
        InputTextComponent,
        MedifinderSearchCardComponent,
        NewMedifinderSearchModalComponent,
        InputDropdownComponent,
        PharmacyToDropdownPipe,
        MedifinderFilterModalComponent,
        MedifinderInfoModalComponent,
    ],
    exports: [
        SvgIconComponent,
        HeaderComponent,
        DashboardCardComponent,
        TextButtonComponent,
        InfoCardComponent,
        BigButtonComponent,
        BackHeaderComponent,
        IconButtonComponent,
        ButtonComponent,
        FilterButtonComponent,
        TabItemComponent,
        InputSearchTextComponent,
        MediaImageComponent,
        SpeechBubbleComponent,
        DepartmentFooterComponent,
        FilterModalComponent,
        ApprovalModalComponent,
        TabsComponent,
        LoadingComponent,
        SkeletonTextComponent,
        NotificationBannerComponent,
        InputCheckboxComponent,
        FilterComponent,
        ImageButtonComponent,
        LoadMoreButtonComponent,
        FederalStateComponent,
        AttachmentsComponent,
        SwipeDirective,
        CalloutComponent,
        InfoModalComponent,
        InputTextComponent,
        MedifinderSearchCardComponent,
        NewMedifinderSearchModalComponent,
        MedifinderFilterModalComponent,
        MedifinderInfoModalComponent,
    ],
    imports: [
        CommonModule,
        I18nModule,
        FormsModule,
        LottieComponent
    ],
})
export class UiModule {
}
