import { Action, createReducer, on } from "@ngrx/store";
import cloneDeep from "lodash-es/cloneDeep";
import {
  loadOnboardingScreens,
  loadOnboardingScreensSuccess,
} from "./onboarding.actions";
import { OnboardingScreen } from "./onboarding";
import { APP_INIT_STATES } from "../status/status";

export interface State {
  onboarding: Array<OnboardingScreen>;
  status: APP_INIT_STATES;
}

export const initialState: State = {
    onboarding: [],
    status: APP_INIT_STATES.INIT
};


const reducer = createReducer(
    initialState,
    on(loadOnboardingScreens, (state, action) => {
        state = cloneDeep(state);
        state.status = APP_INIT_STATES.LOADING;

        return state;
    }),
    on(loadOnboardingScreensSuccess, (state, action) => {
        state = cloneDeep(state);
        state.onboarding = action.response.screens;
        state.status = APP_INIT_STATES.SUCCESS;

        return state;
    }),
);

export function OnboardingReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
