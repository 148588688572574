import { Component } from "@angular/core";
import { AnimationItem } from 'lottie-web';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';


@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent {
  options: AnimationOptions = {
    path: "/assets/animations/logo-loading.json",
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
