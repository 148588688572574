import { Component, OnInit } from "@angular/core";
import { ButtonActionsType } from "../../button/button-actions.type";
import { Router } from "@angular/router";
import { DashboardRoutesType } from "../../../content/dashboard/dashboard-routes.type";
import { headerAnimationsEnterLeave } from "./header.animations";
import { Store } from "@ngrx/store";
import {
  GetUserFullName,
  GetUserInitials,
} from "../../../store/user/user.selector";
import {
  ViewService,
  ViewsExposedFilter,
} from "../../../io/entity/view.service";
import { ModerationSate } from "../../../store/news-post/news-post";
import { ViewType } from "../../../io/entity/types/entityTypes";
import { NavigationService } from "../../../services/navigation.service";
import { BasePageAnimationDirection } from "../../../animations/animation-base/animation.types";
import { ViewHelper } from "../../../io/entity/view-helper/view-helper";
import { base64UrlEncode } from "../../../helper/helper-functions";
import { selectNewsPostByIds } from "../../../store/news-post/news-post.selector";
import { ViewHelperService } from "../../../io/entity/view-helper/view-helper.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [headerAnimationsEnterLeave],
})
export class HeaderComponent implements OnInit {
  public showSearchInput: Boolean = false;
  public initials$;
  public fullname$;
  public searchResult$: any;
  public currentTextFilter = {
    id: ViewType.SearchText,
    value: "",
  };
  public DashboardRoutesType = DashboardRoutesType;
  public firstSearchHappendAlready: boolean = false;

  public viewHelper: ViewHelper;

  constructor(
    private _router: Router,
    private _store: Store,
    private _viewService: ViewService,
    private _navigationService: NavigationService,
    private _viewHelperService: ViewHelperService
  ) {
    this.initials$ = this._store.select(GetUserInitials);
    this.fullname$ = this._store.select(GetUserFullName);
  }

  ngOnInit(): void {}

  public SetEvent(event: any, routeType?: DashboardRoutesType, id?: string) {
    if (routeType) {
      switch (event.type) {
        case ButtonActionsType.Click:
          // navigate to url based on route type and add id if existing
          this._navigationService.navigateByUrl(
            routeType + (id || ""),
            id
              ? BasePageAnimationDirection.LEFT
              : BasePageAnimationDirection.DOWN
          );
          break;
      }
    } else {
      switch (event.type) {
        case ButtonActionsType.Close:
          this.showSearchInput = false;
          this.searchResult$ = null;
          this.firstSearchHappendAlready = false;
          break;
        case ButtonActionsType.Input:
          this.currentTextFilter = event.data;

          this.viewHelper = this._viewHelperService.getViewHelper(
            "dashboard_news" +
              base64UrlEncode(
                JSON.stringify(
                  this.BuildFilterArrayForViewService(this.currentTextFilter)
                )
              ),
            true
          );

          this.viewHelper.setView("global_search", "query");
          this.viewHelper.setFilter(
            this.BuildFilterArrayForViewService(this.currentTextFilter)
          );
          this.viewHelper.setInclude([
            "field_federal_state",
            "field_last_rejected_user",
          ]);
          this.searchResult$ = this.viewHelper.getView$(selectNewsPostByIds);

          this.viewHelper.setActive(false);

          this.firstSearchHappendAlready = true;
          break;
      }
    }
  }

  private BuildFilterArrayForViewService(textFilter: ViewsExposedFilter) {
    let completeFilter: Array<ViewsExposedFilter> = [
      {
        id: ViewType.Bundle,
        value: "news_post",
      },
    ];

    if (textFilter.value) {
      completeFilter.push(textFilter);
    }

    completeFilter.push({
      id: ViewType.ModerationState,
      value: ModerationSate.PUBLISHED,
    });

    return completeFilter;
  }
}
