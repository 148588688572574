import { ChangeDetectorRef, Component } from "@angular/core";

import { AuthService } from "../../store/status/auth/auth.service";
import { BackHeaderImageType } from "../../ui/header/back-header/back-header.type";
import {
  EmailDataStates,
  EmailSelfServiceComponent,
} from "../email-self-service/email-self-service.component";
import { ButtonType } from "../../ui/button/button.type";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../../config/config.service";
import { ApiEmailConnectorService } from "../../store/email/api-email-connector.service";

@Component({
  selector: "app-email-unsubscribe",
  templateUrl: "./email-unsubscribe.component.html",
  styleUrls: [
    "./email-unsubscribe.component.scss",
    "../email-self-service/email-self-service.component.scss",
  ],
})
export class EmailUnsubscribeComponent extends EmailSelfServiceComponent {
  constructor(
    protected override _authService: AuthService,
    protected override _changeDetectorRef: ChangeDetectorRef,
    protected override _router: Router,
    private _http: HttpClient,
    private _configService: ConfigService,
    private _routeSnapshot: ActivatedRoute,
    protected override _apiEmailService: ApiEmailConnectorService
  ) {
    super(_authService, _changeDetectorRef, _router, _apiEmailService);
  }

  public unsubscribe() {
    this.state = EmailDataStates.PROGRESS;

    this._apiEmailService
      .updateStatus({
        ...{ form: { status: 0 } },
        ...this._routeSnapshot.snapshot.queryParams,
      })
      .subscribe({
        next: (response) => {
          this.state = EmailDataStates.SUCCESS;
        },
        error: (error) => {
          this.state = EmailDataStates.ERROR;
          this.errorMessage = `${error.message}`;
        },
      });
  }

  protected readonly BackHeaderImageType = BackHeaderImageType;
  protected readonly EmailDataStates = EmailDataStates;
  protected readonly ButtonType = ButtonType;
}
