import { Injectable, OnDestroy } from "@angular/core";
import { ConfigService } from "../config/config.service";
import { HttpClient } from "@angular/common/http";

export enum QuestionnaireCompletionStatus {
  PENDING = "pending",
  COMPLETED = "completed",
}

export enum QuestionnaireStatus {
  CLOSED = "closed",
  OPEN = "open",
  WILL_OPEN = "will_open",
}

declare global {
  interface Window {
    Paperform: any;
  }
}

window.Paperform = window.Paperform || {};

@Injectable({
  providedIn: "root",
})
export class QuestionnaireService implements OnDestroy {
  constructor(
    private _http: HttpClient,
    private _configService: ConfigService
  ) {}

  private _currentStatus = QuestionnaireStatus.CLOSED;
  private _hideUntil: number = 0;
  private HIDE_FOR_HOURS = 1;
  private SHOW_AFTER_SECONDS = 1;
  private _currentQuestionnaire:
    | {
        enabled: boolean;
        id?: string;
        status?: QuestionnaireCompletionStatus;
      }
    | undefined = undefined;
  private _openTimeout: any;

  /**
   * gets called whenever the dashboard call returns data to parse the questionnaire data
   * @param data
   */
  parseData(data: {
    enabled: boolean;
    id?: string;
    status?: QuestionnaireCompletionStatus;
  }) {
    // set the current questionnaire data
    this._currentQuestionnaire = data;

    // get the hide until date from local storage based on the questionnaire id
    this._hideUntil = parseInt(
      localStorage.getItem(this.getQuestionnaireLocalStorageID()) || "0"
    );

    // check if the questionnaire should be presented to the user
    if (this.shouldPresentQuestionnaire()) {
      this.showQuestionnaire();
    }
  }

  /**
   * get the local storage id for the current questionnaire
   * based on
   * - the questionnaire id
   * - the local storage prefix
   * @private
   */
  private getQuestionnaireLocalStorageID() {
    return `${this._configService.config.storage.localStoragePrefix}${
      this._currentQuestionnaire && this._currentQuestionnaire.id
        ? this._currentQuestionnaire.id
        : ""
    }-questionnaire-hide-until`;
  }

  /**
   * show the questionnaire to the user
   * @private
   */
  private showQuestionnaire() {
    this._currentStatus = QuestionnaireStatus.WILL_OPEN;

    this._openTimeout = setTimeout(() => {
      // set the current status to open, set the hide until date to the current date + HIDE_FOR_HOURS
      this._currentStatus = QuestionnaireStatus.OPEN;
      this._hideUntil = Date.now() + this.HIDE_FOR_HOURS * 60 * 60 * 1000;
      this.saveHideUntil();

      //add a src attribute to page and include the paperform script
      const script = document.createElement("script");
      script.src = "https://paperform.co/__embed.min.js";

      // when the script is loaded, add an event listener for the PaperformSubmission event
      script.onload = () => {
        if (window && window.Paperform) {
          // when the PaperformSubmission event is triggered, save the questionnaire status as completed
          window.addEventListener("PaperformSubmission", (e) => {
            this.saveQuestionnaireStatus(
              QuestionnaireCompletionStatus.COMPLETED
            ).subscribe({
              next: (response) => {
                console.log(response);
              },
              error: (error) => {
                console.error(error);
              },
            });
          });

          // open the questionnaire popup
          window.Paperform.popup(this._currentQuestionnaire?.id || "");
        } else {
          console.error("Paperform not loaded, try again next time.");
          this._currentStatus = QuestionnaireStatus.CLOSED;
          this._hideUntil = 0;
          this.saveHideUntil();
        }
      };

      //append the script
      document.body.appendChild(script);
    }, this.SHOW_AFTER_SECONDS * 1000);
  }

  /*
   * save the hide until date to local storage
   */
  private saveHideUntil() {
    // save the hide until date to local storage
    localStorage.setItem(
      this.getQuestionnaireLocalStorageID(),
      this._hideUntil.toString()
    );
  }

  /**
   * save the questionnaire status to the backend
   * @param status
   * @private
   */
  private saveQuestionnaireStatus(status: QuestionnaireCompletionStatus) {
    return this._http.patch(
      this._configService.config.cms.url + "/api/paperform/status",
      {
        paperform_id: this._currentQuestionnaire?.id,
        status: status,
      }
    );
  }

  /**
   * check if the questionnaire should be presented to the user
   * parameters to determine are
   * - if the questionnaire is enabled
   * - if the questionnaire status is pending
   * - if the current status is closed (popup is not opened)
   * - if the hide until date is in the past
   *
   * @private
   */
  private shouldPresentQuestionnaire() {
    return (
      this._currentQuestionnaire?.enabled &&
      this._currentQuestionnaire.status ===
        QuestionnaireCompletionStatus.PENDING &&
      this._currentStatus === QuestionnaireStatus.CLOSED &&
      this._hideUntil < Date.now()
    );
  }

  public reset() {
    this._hideUntil = 0;
    this.saveHideUntil();

    this.saveQuestionnaireStatus(
      QuestionnaireCompletionStatus.PENDING
    ).subscribe({
      next: (response) => {
        window.location.reload();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  /**
   * clear the timeout when the service is destroyed (e.g. start opening the questionnaire next time)
   */
  ngOnDestroy(): void {
    clearTimeout(this._openTimeout);
  }
}
