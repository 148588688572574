import {
  animate,
  animateChild,
  group,
  query,
  sequence,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const filterModalAnimationsEnterLeave = trigger("enterLeave", [
  transition(":enter", [
    style({ transform: "translateY(100%)" }),
    animate(
      "250ms cubic-bezier(.4, 0, .2, 1)",
      style({ transform: "translateY(0%)" })
    ),
  ]),
  transition(":leave", [
    animate(
      "250ms cubic-bezier(.4, 0, .2, 1)",
      style({ transform: "translateY(100%)" })
    ),
  ]),
]);export const filterModalAnimationsEnterLeaveFade = trigger("enterLeaveFade", [
    transition(":enter", [
        style({opacity: 0}),
        animate(
            "250ms cubic-bezier(.4, 0, .2, 1)",
            style({opacity: 0.2})
        ),
    ]),
    transition(":leave", [
        animate(
            "250ms cubic-bezier(.4, 0, .2, 1)",
            style({opacity: 0})
        ),
    ]),
]);

export const filterModalAnimationsEnterLeaveChild = trigger("enterLeaveChild", [
    transition("closed => open", [
        style({opacity: 0}),
        group([
            animate(
                "100ms cubic-bezier(.4, 0, .2, 1)",
                style({opacity: 1})
            ),
            query("@*", animateChild(), { optional: true }),
        ])
    ]),
    transition("open => closed", [
        sequence([
            query("@*", animateChild(), { optional: true }),
            animate(
                "100ms cubic-bezier(.4, 0, .2, 1)",
                style({opacity: 0})
            ),
            ])

    ]),
]);
