import { createSelector } from "@ngrx/store";
import {
  selectEntitiesByTypeAndModerationState,
  selectEntity,
  selectNewsPostEntitiesByIds,
} from "../../io/entity/store/entity.selector";
import { ModerationSate, NewsPost } from "./news-post";
import { EntityTypesId } from "../../io/entity/types/entityTypes";
import { Entity } from "../../io/entity/types/entity";

export const selectNewsPostById = (id: string) =>
  createSelector(selectEntity(id), (entity) => {
    return transformEntityToNewsPost(entity as NewsPost);
  });

export const selectNewsPostByIds = (ids: Array<string>) =>
  createSelector(selectNewsPostEntitiesByIds(ids), (entities) => {
    return entities.map((entity: Entity) =>
      transformEntityToNewsPost(entity as NewsPost)
    );
  });

export const selectNewsPostByState = (moderationState: ModerationSate) =>
  createSelector(
    selectEntitiesByTypeAndModerationState(
      EntityTypesId.NewsPost,
      moderationState
    ),
    (entities) => {
      return entities.map((entity: Entity) =>
        transformEntityToNewsPost(entity as NewsPost)
      );
    }
  );

function transformEntityToNewsPost(
  entity: NewsPost | undefined
): NewsPost | undefined {
  if (!entity) return entity;

  if (!!entity.relationships?.field_federal_state?.data?.id) {
    entity.attributes.federalStates = [
      entity.relationships.field_federal_state.data.id,
    ];
  } else {
    entity.attributes.federalStates = [];
  }

  return entity;
}
