import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "./onboarding.reducer";
import cloneDeep from "lodash-es/cloneDeep";

export const GetOnboarding = createFeatureSelector<State>("onboarding");

export const GetOnboardingScreens = createSelector(GetOnboarding, (onboarding: State) => {
    let sortedSlides = cloneDeep(onboarding.onboarding);
    sortedSlides?.sort((obj1, obj2) => (obj1.weight > obj2.weight) ? 1 : (obj1.weight < obj2.weight) ? -1 : 0);

    return onboarding.onboarding.length
        ? sortedSlides
        : [];

});

export const GetOnboardingScreensLoadingStatus = createSelector(GetOnboarding, (onboarding: State) => {
    return onboarding.status;
})
