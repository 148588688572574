import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: "root",
})
export class AuthGuardWebGuard {
  constructor(private _router: Router, private _keycloak: KeycloakService) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (
      Capacitor.getPlatform() === "web" &&
      !(await this._keycloak.isLoggedIn())
    ) {
      await this._keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    return true;
  }
}
