import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { GenericOverviewComponent } from "../generic-overview/generic-overview.component";
import { NavigationService } from "../../services/navigation.service";
import { DashboardRoutesType } from "../dashboard/dashboard-routes.type";
import { Store } from "@ngrx/store";
import { AppStore } from "../../store/appStore";
import { base64UrlEncode } from "../../helper/helper-functions";
import { ViewHelper } from "../../io/entity/view-helper/view-helper";
import { ViewHelperService } from "../../io/entity/view-helper/view-helper.service";
import { first, Observable, Subject, take } from "rxjs";
import { MediFinderEntity } from "../../io/entity/types/entity";
import { selectMediFinderSearchesByIds } from "../../store/medifinder/medifinder.selector";
import { listAnimation } from "../../animations/animation-base/element-animation";
import { ButtonOutput } from "../../ui/button/button-output";
import { ButtonActionsType } from "../../ui/button/button-actions.type";
import { GetUserPharmacies } from "../../store/user/user.selector";
import { ActivatedRoute } from "@angular/router";
import { purgeEntities } from "../../io/entity/store/entity.actions";
import { DeviceService } from "../../io/device/device.service";
import { getMedifinderFederalStatesFilter } from "./medifinder-overview-helper";
import { MODAL_ACTIONS, ModalAction } from "../../ui/modal/modal-actions";
import { FilterOption } from "../../ui/header/back-header/back-header.component";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../../config/config.service";

@Component({
  selector: "app-medifinder-overview",
  templateUrl: "./medifinder-overview.component.html",
  styleUrls: [
    "./medifinder-overview.component.scss",
    "../../animations/animation-base/animation-base-page.scss",
  ],
  animations: [listAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class MedifinderOverviewComponent
  extends GenericOverviewComponent
  implements OnInit, OnDestroy
{
  protected readonly DashboardRoutesType = DashboardRoutesType;

  public view: ViewHelper;
  public personalView: ViewHelper;

  public search$: Observable<MediFinderEntity[]>;
  public showPersonalTab = false;

  public newSearchModal = false;

  public activeTab = "global";

  public userHasPharmacies: boolean;

  public filterModalIsOpen = false;

  public mediFinderInfoModalOpen = false;

  constructor(
    protected override _navigationService: NavigationService,
    protected override _changeDetectorRef: ChangeDetectorRef,
    protected override _store: Store<AppStore>,
    protected override _viewHelperService: ViewHelperService,
    private _configService: ConfigService,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _deviceService: DeviceService
  ) {
    super(_navigationService, _changeDetectorRef, _store, _viewHelperService);

    this._route.queryParams.pipe(first()).subscribe((params) => {
      if (params["tab"]) {
        this.activeTab = params["tab"];
      }
    });

    this._subscriptions.push(
      this._deviceService.appBecameActive.subscribe((res) => {
        this.changeView(true);
      })
    );

    try {
      const savedTabNumber = sessionStorage.getItem("personalTab");

      if (savedTabNumber) {
        this.showPersonalTab = parseInt(savedTabNumber) > 0;
      }
    } catch (e) {
      console.error(e);
    }
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  override onUserSet() {
    this.changeView();

    this._store
      .select(GetUserPharmacies)
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) {
          this.userHasPharmacies = false;
          return;
        }

        this.userHasPharmacies = res.length > 0;
      });
  }

  public changeView(force = false) {
    getMedifinderFederalStatesFilter(this._store)
      .pipe(take(1))
      .subscribe((filter) => {
        let user = {
          id: this.activeTab === "global" ? null : this._userID,
        };

        //global view
        this.view = this._viewHelperService.getViewHelper(
          "medifinder-" +
            base64UrlEncode(
              JSON.stringify(this.activeTab === "global" ? filter : []) +
                JSON.stringify(user)
            ),
          false
        );
        this.view.setView("medication_search_api", "default");
        this.view.setFilter(this.activeTab === "global" ? filter : []);
        this.view.setUser(user);
        this.view.setInclude(["owner_pharmacy", "owner_uid"]);
        this.view.setActive(false, undefined, force);

        this.search$ = this.view.getView$(
          selectMediFinderSearchesByIds
        ) as Observable<MediFinderEntity[]>;
      });

    //check if tab should be shown
    this._http
      .get(
        this._configService.config.cms.url +
          "/jsonapi/views/medication_search_api/default?views-argument[]=" +
          this._userID
      )
      .subscribe({
        next: (res) => {
          try {
            this.showPersonalTab = res["data" as keyof Object].length > 0;
            console.log("show personal tab", this.showPersonalTab);
          } catch (e) {
            this.showPersonalTab = false;
            sessionStorage.setItem("personalTab", "0");
          }
        },
        error: (err) => {
          this.showPersonalTab = false;
          sessionStorage.setItem("personalTab", "0");
        },
      });
  }

  refresh(event: Subject<any> | undefined = undefined, message: string = "") {
    this._store.dispatch(purgeEntities());

    this.changeView();
    if (event) {
      event.next(false);
    }
  }

  onContainerScroll(e: Event): void {
    this.enablePullToRefresh = !(
      e.target && (e.target as HTMLHtmlElement).scrollTop > 50
    );
  }

  public triggerNewSearchModal() {
    this.newSearchModal = !this.newSearchModal;
  }

  public override SetEvent(
    event: ButtonOutput,
    routeType?: DashboardRoutesType,
    id?: number | string | undefined
  ) {
    switch (event.type) {
      case ButtonActionsType.Tab:
        this.activeTab = event.data;
        this.changeView();
        break;
      case ButtonActionsType.Filter:
        this.filterModalIsOpen = !this.filterModalIsOpen;
        break;
      default:
        super.SetEvent(event, routeType, id, { tab: this.activeTab });
        break;
    }
  }

  public SetAction(event: ModalAction) {
    switch (event.action) {
      case MODAL_ACTIONS.filter:
        this.filterModalIsOpen = false;
        this.changeView();
        break;
    }
  }

  protected readonly FilterOption = FilterOption;
}
