import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import {
  baseModalAnimationsAppear,
  baseModalAnimationsEnterLeave,
} from "./base-modal.animations";
import { ModalAction } from "../modal-actions";

@Component({
  selector: "app-base-modal",
  template: "",
  styleUrls: [],
  animations: [baseModalAnimationsAppear, baseModalAnimationsEnterLeave],
})
export class BaseModalComponent {
  @HostBinding("class.modal") modal = true;

  @Input() @HostBinding("class.modal--open") open = false;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() action: EventEmitter<ModalAction> = new EventEmitter<ModalAction>();

  public locked = false;

  constructor() {}

  ngOnInit(): void {}

  public Close() {
    if (!this.locked) {
      this.open = false;
      this.openChange.emit(this.open);
    }
  }

  public Action(action: ModalAction) {
    this.action.emit(action);
  }
}
