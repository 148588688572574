import { Injectable } from "@angular/core";
import { ApiConnectorService } from "../api-connector.service";
import { ConfigService } from "../../config/config.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiEntityResponse } from "../entity/types/api-entity-response";

@Injectable({
  providedIn: "root",
})
export class ApiConnectorDeviceService extends ApiConnectorService {
  constructor(public configService: ConfigService, public http: HttpClient) {
    super(configService);
  }

  public setDevice(payload: any): Observable<any> {
    return this.http.patch(
      this.configService.config.cms.url + "/api/dashboard",
      { device: payload }
    ) as Observable<ApiEntityResponse>;
  }

  public deactivateDevice(bundle: string, deviceId: any): Observable<any> {
    return this.http.patch(
      this.configService.config.cms.url +
        "/api/user-devices/" +
        bundle +
        "/" +
        deviceId,
      { status: 0 }
    );
  }
}
